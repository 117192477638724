<template>
  <div>

    <div class="border border-solid border-t-0 border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajaxPagination="true"
        :columns="users.columns"
        :query="users.query"
        :loading="users.loading"
        :url="users.url"
        :onClick="click"
        sort="created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="users.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
          <!-- <div class="flex items-start flex-wrap xl:flex-no-wrap">
            <div class="flex flex-wrap items-center w-full">
              <span class="processor-filter-title">Filters:</span>
              <template v-for="(filter, index) in users.filters">
                <span
                  v-html="filter.title"
                  :key="index"
                  class="processor-filter-item"
                  :class="{ active: currentFilter === filter }"
                  @click.prevent="toggleFilter(filter)"
                />
              </template>
            </div>
          </div> -->
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />

  </div>
</template>

<script>
  export default {
    data() {
      return {
        currentFilter: null,
        users: this.$options.resource([], {
          url: `${this.$baseurl}/admin/personal/users/status/incomplete-one`,
          query: '',
          selected: null,
          columns: [
            {
              name: 'id',
              th: 'User ID',
            },
            {
              name: 'name',
              th: 'Name',
              render: (user) => user.name + ' ' + user.last_name
            },
            {
              name: 'email',
              th: 'Email Address',
            },
            {
              name: 'progress',
              th: 'Progress',
              render: (user) => {
                return this.progressIcons(user);
              },
              sortable: false
            },
            {
              name: 'admins',
              th: 'Assigned To',
              render: (user) => {
                return this.assignedAdmin(user)
              },
              sortable: false
            },
            {
              name: 'updated_at',
              th: 'Date Approved',
              render: (user, updated_at) => this.$moment(updated_at).format('MMM Do YYYY')
            },
            {
              name: 'created_at',
              th: 'Date Registered',
              render: (user, created_at) => this.$moment(created_at).format('MMM Do YYYY')
            }
          ],
          filters: [
            { title: 'pre-approved', name: 'pre-approved' },
            { title: 'approved', name: 'approved' },

            { title: 'verified', name: 'verified' },
            { title: 'completed application', name: 'processing' },
            { title: 'incompleted application', name: 'incomplete' },
          ],
        }),
      }
    },
    methods: {
      click(user) {
        this.users.selected = user;
        this.$refs.userModal.open();
      },
      reloadTable() {
        this.$refs.table.loadAjaxData()
      },
      toggleFilter(filter) {
        if (this.currentFilter === filter) {
          this.currentFilter = null
        }else {
          this.currentFilter = filter
        }

        this.$refs.table.clickedFilter(this.currentFilter)
      },
      userDeleted() {
        this.reloadTable();
      },
      userModalClosed() {
        this.users.selected = null;
      }
    }
  }
</script>
